import React from "react";

const Header = () => {
  return (
    <div className="flex w-full p-4 font-semibold text-md">
      <h1>Meme Trading Bot</h1>
    </div>
  );
};

export default Header;
