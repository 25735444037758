import React from "react";
import Container from "../components/Container";
import { mover_data } from "../data/data";
import ReactPaginate from "react-paginate";
import { ArrowLeft2, ArrowRight2 } from "iconsax-react";

const TopMovers = () => {
  return (
    <Container
      title="Top Movers"
      containerStyle="xl:w-5/12 w-full"
      childrenStyle="max-h-[692px]"
    >
      <table className="w-full font-medium my-4">
        <thead>
          <tr className="flex items-center border-b border-[#FFFFFF66]">
            <td className="w-2/4 text-start px-4 py-2">Coin</td>
            <td className="w-1/4  text-start px-4 py-2">Volume</td>
            <td className="w-1/4 text-start px-4 py-2">Avg Price</td>
          </tr>
        </thead>
        <tbody>
          {mover_data.map((item, index) => (
            <tr key={index} className="flex items-center hover:bg-[#141619]">
              <td className="w-2/4 text-start px-4 py-3">
                <div className="flex items-center">
                  <img
                    src={item.token_image}
                    alt={item.short_token_name}
                    className="w-8 h-8"
                  />
                  <div className="flex flex-col justify-center ml-2">
                    <div className="flex text-sm gap-1">
                      <p>{item.token_name}</p>
                      <p className="text-[#FFFFFFCC]">
                        {item.short_token_name}
                      </p>
                    </div>
                  </div>
                </div>
              </td>
              <td className="w-1/4 text-start px-4 py-3">{item.volume}</td>
              <td className="w-1/4 text-start px-4 py-3">{item.avg_price}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <div className="relative flex w-full p-4">
            <ReactPaginate
              // onPageChange={(e) => handlePageClick(e)}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              pageCount={10}
              forcePage={1}
              breakLabel="..."
              previousLabel={<ArrowLeft2 size="16px" />}
              nextLabel={<ArrowRight2 size="16px" />}
              nextClassName="paginator-page-btn absolute right-4 border border-[#FFFFFF33] rounded-md"
              previousClassName="paginator-page-btn absolute right-[58px] border border-[#FFFFFF33] rounded-md"
              containerClassName="flex rounded-xl font-medium gap-2"
              activeClassName="paginator-page-btn bg-[#7FF91B] text-[#000000]"
              pageClassName="paginator-page-btn border border-[#FFFFFF33] rounded-md"
              breakClassName="paginator-page-btn"
            />
          </div>
        </tfoot>
      </table>
    </Container>
  );
};

export default TopMovers;
