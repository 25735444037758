import React from "react";
import Container from "../components/Container";
import Transaction from "../modules/Transaction";
import TopMovers from "../modules/TopMovers";

const MainPage = () => {
  return (
    <div className="flex flex-col w-full p-4 gap-5">
      <div className="flex flex-col xl:flex-row w-full gap-4">
        <Transaction />
        <TopMovers />
      </div>
      <Container title="Dexscreener" containerStyle="w-full">
        <div id="dexscreener-embed">
          <iframe
            title="dexscreener"
            src="https://dexscreener.com/iotex/0x55675984d54BAe52354320894eA1864aF952cbbc?embed=1&loadChartSettings=0&chartLeftToolbar=0&chartDefaultOnMobile=1&chartTheme=dark&theme=dark&chartStyle=0&chartType=usd&interval=15"
          ></iframe>
        </div>
      </Container>
    </div>
  );
};

export default MainPage;
