import React from "react";
import Container from "../components/Container";
import { transaction_data } from "../data/data";
import { ArrowRight, Link2 } from "iconsax-react";
import useShorten from "../hooks/shorten";

const Transaction = () => {
  const shorten = useShorten();
  return (
    <Container
      title="Transaction"
      containerStyle="xl:w-7/12 w-full"
      childrenStyle="max-h-[692px] overflow-y-auto overflow-x-auto"
    >
      <table className="w-full font-medium">
        <thead className="sticky top-0 bg-[#0A0B0D]">
          <tr className="flex items-center border-b border-[#FFFFFF66] mt-4">
            <td className="w-2/12 text-start px-4 py-2">Date</td>
            <td className="w-5/12 text-start px-4 py-2">Coin</td>
            <td className="w-1/12 text-start px-4 py-2">Action</td>
            <td className="w-1/12 text-start px-4 py-2">Chain</td>
            <td className="w-3/12 text-end px-4 py-2">Price</td>
          </tr>
        </thead>
        <tbody>
          {transaction_data.map((item, index) => (
            <tr
              key={index}
              className="flex items-center hover:bg-[#141619] text-nowrap"
            >
              <td className="w-2/12 text-start px-4 py-2">{item.date}</td>
              <td className="w-5/12 text-start px-4 py-2">
                <div className="flex items-center">
                  <img
                    src={item.token_image}
                    alt={item.short_token_name}
                    className="w-8 h-8"
                  />
                  <div className="flex flex-col justify-center ml-2">
                    <div className="flex text-sm gap-1">
                      <p>{item.token_name}</p>
                      <p className="text-[#FFFFFFCC]">
                        {item.short_token_name}
                      </p>
                    </div>
                    <div className="flex text-xs text-[#99FA49] items-center hover:underline gap-2 cursor-pointer font-normal">
                      <p className="flex gap-1 items-center">
                        <Link2 size="14" />
                        Transaction Hash
                      </p>
                      <p className="flex gap-1 items-center">
                        <ArrowRight size="14" />
                        {shorten(item.transaction_hash)}
                      </p>
                    </div>
                  </div>
                </div>
              </td>
              <td className="w-1/12 text-start px-4 py-2">
                <p
                  className={`rounded-lg py-1 w-12 text-center ${
                    item.action === "Buy"
                      ? "bg-[#22C55E33] text-green-500"
                      : "bg-[#FF303033] text-red-500"
                  }`}
                >
                  {item.action}
                </p>
              </td>
              <td className="w-1/12 text-start px-4 py-2">
                <div className="flex items-center gap-0.5">
                  <img
                    src={item.chain_icon}
                    className="w-6 h-6"
                    alt="chainIcon"
                  />
                  {item.chain}
                </div>
              </td>
              <td className="w-3/12 text-end px-4 py-2">
                <div className="flex flex-col">
                  <p>{item.floor_price}</p>
                  <p
                    className={`${
                      item.floor_increase > 0
                        ? "text-green-500"
                        : "text-red-500"
                    }`}
                  >
                    {item.floor_increase > 0 ? "+" : ""}
                    {item.floor_increase}%
                  </p>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

export default Transaction;
