import React from "react";

const Container = ({
  title,
  children,
  childrenStyle = "",
  containerStyle = "",
}) => {
  return (
    <div className={`border border-[#7FF91B] ${containerStyle}`}>
      <div className="flex w-full bg-[#7FF91B] py-2 px-4 text-md font-semibold text-black">
        {title}
      </div>
      <div className={childrenStyle}>{children}</div>
    </div>
  );
};

export default Container;
