export const transaction_data = [
  {
    date: "2021-10-03",
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    action: "Buy",
    chain: "ETH",
    chain_icon: "https://placehold.co/100x100/FFAABB/000000?text=Chain+Icon",
    floor_price: "$0.000001",
    floor_increase: 3,
    transaction_link: "https://www.google.com",
    transaction_hash: "0x1234567890",
  },
  {
    date: "2021-10-03",
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    action: "Buy",
    chain: "BSC",
    chain_icon: "https://placehold.co/100x100/FFAABB/000000?text=Chain+Icon",
    floor_price: "$0.000001",
    floor_increase: 2,
    transaction_link: "https://www.google.com",
    transaction_hash: "0x1234567890",
  },
  {
    date: "2021-10-03",
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    action: "Sell",
    chain: "SOL",
    chain_icon: "https://placehold.co/100x100/FFAABB/000000?text=Chain+Icon",
    floor_price: "$0.000001",
    floor_increase: -5,
    transaction_link: "https://www.google.com",
    transaction_hash: "0x1234567890",
  },
  {
    date: "2021-10-03",
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    action: "Buy",
    chain: "BSC",
    chain_icon: "https://placehold.co/100x100/FFAABB/000000?text=Chain+Icon",
    floor_price: "$0.000001",
    floor_increase: -6,
    transaction_link: "https://www.google.com",
    transaction_hash: "0x1234567890",
  },
  {
    date: "2021-10-03",
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    action: "Sell",
    chain: "ETH",
    chain_icon: "https://placehold.co/100x100/FFAABB/000000?text=Chain+Icon",
    floor_price: "$0.000001",
    floor_increase: 5,
    transaction_link: "https://www.google.com",
    transaction_hash: "0x1234567890",
  },
  {
    date: "2021-10-03",
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    action: "Buy",
    chain: "ETH",
    chain_icon: "https://placehold.co/100x100/FFAABB/000000?text=Chain+Icon",
    floor_price: "$0.000001",
    floor_increase: 3,
    transaction_link: "https://www.google.com",
    transaction_hash: "0x1234567890",
  },
  {
    date: "2021-10-03",
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    action: "Buy",
    chain: "BSC",
    chain_icon: "https://placehold.co/100x100/FFAABB/000000?text=Chain+Icon",
    floor_price: "$0.000001",
    floor_increase: 2,
    transaction_link: "https://www.google.com",
    transaction_hash: "0x1234567890",
  },
  {
    date: "2021-10-03",
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    action: "Sell",
    chain: "SOL",
    chain_icon: "https://placehold.co/100x100/FFAABB/000000?text=Chain+Icon",
    floor_price: "$0.000001",
    floor_increase: -5,
    transaction_link: "https://www.google.com",
    transaction_hash: "0x1234567890",
  },
  {
    date: "2021-10-03",
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    action: "Buy",
    chain: "BSC",
    chain_icon: "https://placehold.co/100x100/FFAABB/000000?text=Chain+Icon",
    floor_price: "$0.000001",
    floor_increase: -6,
    transaction_link: "https://www.google.com",
    transaction_hash: "0x1234567890",
  },
  {
    date: "2021-10-03",
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    action: "Sell",
    chain: "ETH",
    chain_icon: "https://placehold.co/100x100/FFAABB/000000?text=Chain+Icon",
    floor_price: "$0.000001",
    floor_increase: 5,
    transaction_link: "https://www.google.com",
    transaction_hash: "0x1234567890",
  },
  {
    date: "2021-10-03",
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    action: "Buy",
    chain: "ETH",
    chain_icon: "https://placehold.co/100x100/FFAABB/000000?text=Chain+Icon",
    floor_price: "$0.000001",
    floor_increase: 3,
    transaction_link: "https://www.google.com",
    transaction_hash: "0x1234567890",
  },
  {
    date: "2021-10-03",
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    action: "Buy",
    chain: "BSC",
    chain_icon: "https://placehold.co/100x100/FFAABB/000000?text=Chain+Icon",
    floor_price: "$0.000001",
    floor_increase: 2,
    transaction_link: "https://www.google.com",
    transaction_hash: "0x1234567890",
  },
  {
    date: "2021-10-03",
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    action: "Sell",
    chain: "SOL",
    chain_icon: "https://placehold.co/100x100/FFAABB/000000?text=Chain+Icon",
    floor_price: "$0.000001",
    floor_increase: -5,
    transaction_link: "https://www.google.com",
    transaction_hash: "0x1234567890",
  },
  {
    date: "2021-10-03",
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    action: "Buy",
    chain: "BSC",
    chain_icon: "https://placehold.co/100x100/FFAABB/000000?text=Chain+Icon",
    floor_price: "$0.000001",
    floor_increase: -6,
    transaction_link: "https://www.google.com",
    transaction_hash: "0x1234567890",
  },
  {
    date: "2021-10-03",
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    action: "Sell",
    chain: "ETH",
    chain_icon: "https://placehold.co/100x100/FFAABB/000000?text=Chain+Icon",
    floor_price: "$0.000001",
    floor_increase: 5,
    transaction_link: "https://www.google.com",
    transaction_hash: "0x1234567890",
  },
];

export const mover_data = [
  {
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    volume: "30.12",
    avg_price: "30.02",
  },
  {
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    volume: "30.12",
    avg_price: "30.02",
  },
  {
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    volume: "30.12",
    avg_price: "30.02",
  },
  {
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    volume: "30.12",
    avg_price: "30.02",
  },
  {
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    volume: "30.12",
    avg_price: "30.02",
  },
  {
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    volume: "30.12",
    avg_price: "30.02",
  },
  {
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    volume: "30.12",
    avg_price: "30.02",
  },
  {
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    volume: "30.12",
    avg_price: "30.02",
  },
  {
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    volume: "30.12",
    avg_price: "30.02",
  },
  {
    token_name: "Token Name",
    short_token_name: "TKN",
    token_image: "https://placehold.co/400x400/AABBFF/000000?text=Token+Image",
    volume: "30.12",
    avg_price: "30.02",
  },
];
